import { mapToCanActivate, Routes } from '@angular/router'
import {
   CoachingComponent,
   ContentShellComponent,
   CourseLibraryComponent,
   DashboardComponent,
   FaqComponent,
   MeetTheMentorsComponent,
   ProgressComponent,
   ResourcesComponent,
   VideoLibraryComponent
} from './containers'
import { ComponentType_ } from '@appShared/services/lookup/[CodeGen]'
import { AuthGuardUserService } from '@appShared/services/auth-guard-user.service'
import { environment } from '@appEnvironments/environment'

const contentRoutes = environment.routes.content

const childAccountRoutes: Routes = [
   {
      path: '',
      component: DashboardComponent,
   },
   {
      path: contentRoutes.coaching.baseUri,
      component: CoachingComponent
   },
   {
      path: contentRoutes.courseLibrary.baseUri,
      component: CourseLibraryComponent,
      data: {
         componentTypeCode: ComponentType_.CourseLibrary
      }
   }, {
      path: contentRoutes.faq.baseUri,
      component: FaqComponent
   },
   {
      path: contentRoutes.meetTheMentors.baseUri,
      component: MeetTheMentorsComponent
   },
   {
      path: contentRoutes.progress.baseUri,
      component: ProgressComponent
   },
   {
      path: contentRoutes.subMenu.baseUri,
      component: ResourcesComponent
   },
   {
      path: contentRoutes.videoLibrary.baseUri,
      component: VideoLibraryComponent
   }
]

export const CONTENT_ROUTES: Routes = [
   {
      path: contentRoutes.baseUri, /* If not lazy-loading contentRoutes.baseUri, else '' */
      component: ContentShellComponent,
      canActivate: mapToCanActivate([AuthGuardUserService]), /* remove if lazy-loading */
      children: childAccountRoutes
   }
]
