import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { UrlService } from '@appShared/services/url.service'
import { IFramePlayerComponent } from '@appShared/components/iframe-player/iframe-player.component';
import { environment } from '@appEnvironments/environment'

@Component({
    selector: 'app-content-coaching',
    styles: [`
      .page-title {
         /*background-color: white!important;*/
      }
   `],
    template: `
      <div class="d-flex flex-column h-100 w-100 bg-white">
         <!--<div class="fs-2 text-center pt-2 fw-bold text-uppercase page-title">{{coachingTitle}}</div>-->
         <app-iframe-player [frameSourceInput]="coachingUri"></app-iframe-player>
      </div>
   `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IFramePlayerComponent]
})
export class CoachingComponent implements OnInit {
   coachingUri: string
   coaching = environment.routes.content.coaching
   coachingTitle = this.coaching.title

   private _urlService = inject(UrlService)

   constructor() {
      const experienceUri = encodeURIComponent(environment.brightcove.experiences.coachingUrl)
      if (experienceUri) {
         this.coachingUri = experienceUri
      }
   }

   ngOnInit() {
      setTimeout(() => { this._urlService.setHeaderTitle(this.coachingTitle, true/*setBrowserTitle*/) })
   }
}
